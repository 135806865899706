#preloader {
	background-color: #fff;
	padding: 0;
	margin: 0;
	height: 100%;
	position: fixed;
	z-index: 99999;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.lds-ripple {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}

	.lds-ripple div {
		position: absolute;
		border: 4px solid var(--primary);
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}

	.lds-ripple div:nth-child(2) {
		animation-delay: -0.5s;
	}

	@keyframes lds-ripple {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}

		100% {
			top: 0px;
			left: 0px;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}
}

[data-theme-version="dark"] {
	#preloader {
		background-color: $d-bg;
	}
}

.loading-indicator {
	border: 5px solid #d1d1d1;
	border-radius: 50%;
	border-top: 5px solid $color-loading-indicator;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}