.dialog-blur {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.dialog-content {
	width: 720px;
	padding: 10px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.dialog-content img {
	width: 100%;
	height: auto;
	object-fit: contain;
	max-height: 720px;
}


.dialog-content span {
	text-align: right;
}

.bg-disabled {
	background: #F5F5F5 !important;
}

.text-selected {
	font-weight: 600 !important;
	color: #000 !important;
}

.text-customers-title {
	color: #6E98DB !important;
}

.text-vendors-title {
	color: #44886E !important;
}

.text-accounts-title {
	color: #DF9290 !important;
}

.bg-menu-item-selected {
	background-color: #9ECCF5 !important;
}

.bg-menu-subitem-selected {
	background-color: #E2F1FF !important;
}

.text-header {
	color: #36365D !important;
}

.text-menu-label {
	color: #36365D !important;
}

.text-menu-label-active {
	font-weight: 500 !important;
	color: #36365D !important;
}

.color-column-name {
	color: #6e70ae !important;
}

.layout-dashboard-customers {
	border-left: 5px solid #6E98DB !important;
	border-radius: 5px !important;
}

.layout-dashboard-vendors {
	border-left: 5px solid #44886E !important;
	border-radius: 5px !important;
}

.layout-dashboard-accounts {
	border-left: 5px solid #DF9290 !important;
	border-radius: 5px !important;
}

.align-start {
	text-align: start !important;
}

.align-end {
	text-align: end !important;
}